<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128"> 
      <Spinner v-if="loading" />
      <Alert v-else-if="alert" v-bind="{alert}" />
      <template v-else-if="item">
        <h1 class="text-lg mb-4">
          <BoxSeam :size="20" class="inline mb-1 mr-1" />
          {{$t('show.shipment-from')}} <span class="font-semibold">{{this.item.merchant_name}}</span>
        </h1>

        <p class="mb-4" v-text="$t('input.info')"></p>

        <div v-if="item.contents" class="mb-4">
          <span class="font-medium">Contents:</span>
          {{$t(item.contents)}}
        </div>

        <Button @click="select" class="w-full" :class="{
          'bg-gray-100 border border-gray-200': location,
          'bg-brand': !location,
        }">
          <div v-if="location">
            <span class="font-medium slashed-zero"><Geo class="inline" /> {{location.address.company}}</span><br>
            {{location.address.street}}, {{location.address.locality}}
          </div>
          <span v-else><Geo class="inline" /> {{$t('input.select-location')}}</span>
        </Button>
        <Button @click="confirm" v-if="location" class="w-full mt-4 bg-brand self-center" v-text="$t('input.confirm')"/>
      </template>
    </FloatingBox>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '../layouts/Default'
import FloatingBox from '../components/FloatingBox'
import Spinner from '../components/Spinner.vue'
import Alert from '../components/Alert.vue'
import Warning from '../alerts/Warning'
import Error from '../alerts/Error'
import BoxSeam from '../icons/BoxSeam.vue'
import Geo from '../icons/Geo.vue'
import Button from '../components/Button.vue'

import Clickbox from 'clickbox-widget'

import api from '../api'
import Success from '../alerts/Success'

export default {
  name: 'Input',
  components: {
    DefaultLayout,
    FloatingBox,
    Spinner,
    Alert,
    BoxSeam,
    Geo,
    Button
  },
  props: {
    referenceNumber: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      alert: null,
      item: null,
      location: null,
      clickbox: new Clickbox()
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async select() {
      const location = await this.clickbox
        .setToken(this.item.merchant_token)
        .selectLocation({
          selected: this.location?.id
        })

      if(location) {
        this.location = location
      }

    },
    async fetch() {
      try {
        const {data} = await api.get(`input/${this.type}/${this.referenceNumber}`)
        this.item = data.data
      } catch (error) {
        this.alert = this.handleError(error)
      } finally {
        this.loading = false
      }
    },
    async confirm() {
      this.loading = true

      try {
        await api.post(`input/${this.type}/${this.referenceNumber}`,{
          location: this.location.id
        })

        this.alert = new Success(this.$t('input.alerts.success'),this.$t('input.alerts.success-info'))

      } catch (error) {
        this.alert = this.handleError(error)
      } finally {
        this.loading = false
      }
    },
    handleError(error) {
        switch(error.response?.status) {
          case 403:
            return new Warning(this.$t('input.alerts.forbidden'),this.$t('input.alerts.forbidden-info'))
          case 404:
            return new Warning(this.$t('input.alerts.not-found'),this.$t('input.alerts.not-found-info'))
          case 500:
          default:
            return new Error(this.$t('input.alerts.unknown'),error.message || this.$t('input.alerts.unknown-info'))
        }
    }
  }
}
</script>